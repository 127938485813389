.accordion {
    border-width: 0.5px 0 0 0;
    border-style: solid;
    border-color: #000;
    padding: 5px 0;
    margin: 5px 0;
}

.accordion > h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.info-button {
    position: relative; 
    display: inline-block;
}

.info-button > span {
    cursor: pointer;
    margin-left: 8px;
    font-size: 14px;
    color: #555;
    padding: 2px 5px;
    font-weight: bold;
}

.info-panel {
    position: relative;
    top: 100%;
    left: 0;
    margin-top: 8px;
    padding: 8px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 200px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.1);
    z-index: 1;
}


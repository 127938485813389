.toggle-panel {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 30px;
  overflow: hidden;
  width: 200px;
}

.toggle-option {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #444;
  background-color: white;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-option.selected {
  background-color: #73a580;
  color: white;
  border-radius: 30px; /* Apply rounded corners to the selected side */

}

.toggle-option:not(.selected):hover {
  background-color: #f1f1f1;
}

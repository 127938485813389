@import url("https://fonts.googleapis.com/css2?family=Eczar&family=Frank+Ruhl+Libre&display=swap");

/* General page container */
.homepage-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #f8fafc;
}

/* Header */
.header {
  background-color: #f8fafc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
.header-title {
  font-size: 2.4rem;
  font-weight: bold;
  color: #912f40;
  margin: 15px;
}

/* Hero Section */
.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)),
    url("../../../public/background.png");
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 48px 32px 32px 32px;
  color: white;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: height 0.5s ease, padding 0.5s ease;
}
.hero-title {
  font-size: 3rem;
  font-weight: 900;
  color: white;
  margin-bottom: 40px;
}
.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero-content p {
  margin: 0;
}
.hero-subtitle {
  font-size: 2.4rem;
  margin: 8px 0 20px;
}


/* Feature Section */
.features {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  padding: 32px;
  height: auto;
}
.feature-item {
  background-color: #f8fafc;
  border: 1px solid #d0dbe7;
  border-radius: 8px;
  padding: 16px;
  text-align: left;
}
.feature-title {
  font-size: 2.4rem;
  color: #0e141b;
  font-weight: bold;
}
.feature-description {
  font-size: 1.8rem;
  color: #343f4a;
}

/* Footer */
.footer {
  height: auto;
  text-align: center;
  padding: 16px;
}
.footer-text {
  font-size: 0.875rem;
  color: #4e7397;
}
.footer-divider {
  height: 20px;
  background-color: #f8fafc;
}

/* Media Queries */
@media (max-width: 400px) {
  .features {
    display: flex;
    flex-direction: column;
  }
  .feature.item {
    hyphens: auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}

@media (min-width: 700px) {
  .header-title {
    font-size: 4rem;
    margin: 2rem;
  }
  .hero-title {
    font-size: 4rem;
    margin: 2rem 0 5rem;
  }
  .hero-content {
    max-width: 700px;
    justify-content: space-around;
  }
  .features {
    justify-content: center;
    max-width: 700px;
  }
  .feature-title {
    font-size: 2.2rem;
  }
  .feature-description {
    font-size: 1.6rem;
  }
}

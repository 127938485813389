.day-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.day-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.date {
  display: flex;
  justify-content: center;
  align-items: center;
}
.date > h4 {
    margin: 0;
}

.day-buttons > toggle-option {
  padding: 10px;
}

.left-button {
  margin-right: auto;
  width: 15px;
}

.center-button {
  margin: 0 auto;
  text-align: center;
}

.right-button {
  margin-left: auto;
  width: 15px;
}

@media (min-width: 750px) {
  .day-buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
  }
}
